@use 'styles/globals/breakpoints';

$set-background: rgba(#2e303b, 0.9);

.block {
	display: flex;
	position: fixed;
	flex-direction: column;
	bottom: 2vh;
	width: 92vw;
	min-height: 200px;
	background-color: $set-background;
	padding: 1rem 2rem 2rem 2rem;
	margin: 0 4vw;
	z-index: 999;
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
	border-bottom-left-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
	transition: var(--easeInOutBack);

	@media only screen and (max-width: breakpoints.$screen-md) {
		display: none;
	}
}

.hideBlock {
	bottom: -250px;
	display: none;
}

.minimizeBlock {
	bottom: -132px;
}

.minimizeBtn {
	padding: 1rem 3rem;
	line-height: 1;
	background-color: transparent;
	color: var(--white);
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-left: 2px solid #666;
	font-size: 1.6rem;
	display: flex;
	align-items: center;
	align-content: center;

	@media only screen and (max-width: breakpoints.$screen-sm) {
		border: none;
		padding: 1rem 1rem 1rem 0;
		width: 100%;
	}
}

.lessIcon {
	transform: rotate(-90deg);
}

.moreIcon {
	transform: rotate(90deg);
}

.header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: breakpoints.$screen-sm) {
		flex-direction: row;
	}
}

.heading {
	color: var(--white);
	margin: 0;
	padding-right: 2rem;

	@media only screen and (max-width: breakpoints.$screen-sm) {
		width: auto;
	}
}

.headingCntr {
	display: flex;
	align-items: center;

	@media only screen and (max-width: breakpoints.$screen-sm) {
		width: 50%;
		flex-direction: column;
		justify-content: flex-start;
	}
}

.list {
	list-style: none;
	margin: 1rem 0 0 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	align-content: flex-start;
	overflow-x: auto;
	overflow-y: hidden;
}

.image {
	width: auto !important;
	height: 100%;
	object-fit: contain;
    margin-right: 1rem;
}

.item {
	background-color: #2e303b;
	border-radius: var(--border-radius);
	height: 100px;
	position: relative;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 1rem;
	padding: 1rem;
	margin: 0 0 0 2rem;
	min-width: 300px;

	&:last-of-type {
		margin-right: 0;
	}

	&:first-of-type {
		margin-left: 0 !important;
	}
}

.itemHeading {
	margin: 0 0 1rem 0;
	line-height: 1.1;
	font-size: 1.2rem;
}

.activeItem {
	background-color: var(--white);
	display: flex;
	flex-direction: row;
	box-shadow: var(--box-shadow);

	&::before {
		content: "";
		flex: auto;
	}
}

.buttonGroup {
	display: flex;
	align-content: center;
	align-items: center;
	& * {
		color: var(--white) !important;
	}
}

.submitIcon {
	margin: 0 -10px -3px 0 !important;
	fill: var(--white);
}

.removeBtn {
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	height: 30px;
	background-color: var(--ocean);
	color: var(--white);
	border-top-right-radius: var(--border-radius);
	border-bottom-left-radius: var(--border-radius);
	line-height: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	box-shadow: var(--box-shadow);
}

.productMeta {
	padding: 0 2rem 0 0;
	width: 80%;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.metaList {
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	justify-content: space-between;
	width: 100%;
}

.checked {
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 5px;
	line-height: 1;
	background-color: #ffffff90;
	box-shadow: var(--box-shadow-lg);
}

.successIcon {
	fill: var(--green);
}

.errorIcon {
	fill: var(--terracotta);
}

.slider {
	width: 100%;

	& .item {
		&:last-of-type {
			margin-right: 2rem;
		}
	}
}
